import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <section id="about2">
        <img src="img/about-madeleine.jpg" alt="img/about-madeleine.jpg" />
        <div>
          <div class="block">
            <h3>AWARDS</h3>
            <p>2017 Tait Memorial Trust Andrew Loewenthal Award</p>
            <p>2016 The University of Sydney Rose Mandelbaum Scholarship</p>
            <p>2016 The Guildhall School Guildhall School Scholarship</p>
            <p>2015 BBM Youth Support Award Travel Scholarship</p>
            <p>
              2014 – 2016 University of Tasmania University Scholarship in
              Orchestral Wind Instruments
            </p>
            <p>
              2010 United Music Teachers Association Presidents Award: Open Age
              Instrumentalist NSW
            </p>
          </div>

          <h2 id="about">About the maker</h2>

          <p>
            <br />
            Originating from Sydney, Madeleine Randall is a professional oboist;
            performer, teacher and reed-maker. An International artist,
            Madeleine has performed in venues such as: The Sydney Opera House
            (Solo Artist 2008), BBC 3 National Radio (Total Immersion Concert
            2017), Trafalgar Square London (The BMW/London Symphony Orchestra
            Open Air Classics 2018) and has toured extensively across America
            and China.
          </p>

          <p>
            <br />
            Madeleine graduated with Distinction from the Guildhall School of
            Music and Drama in London, completing the Artist Masters Program.
            Whilst completing her degree she performed across London as a
            soloist, chamber and orchestral musician and worked within the
            London Symphony Orchestra’s Educational Outreach Programs bringing
            music to local London schools, mixed ability groups and children
            with limited access to music programs. Madeleine also holds her
            Bachelor of Music from the Sydney Conservatorium of Music (USYD) and
            BMus Hons in Orchestral Studies from the University of Tasmania
            (UTAS).
          </p>

          <p>
            <br />
            Now returned to Sydney, Madeleine freelances with multiple
            orchestra’s and is a casual musician with Opera Australia. She
            continues to teach from her studio in Queenscliff and schools across
            Sydney.
          </p>
          
          <p><br /><b>2024 lesson slots are available.</b> If you are interested in learning Oboe, Clarinet, Saxophone or Music Theory with Madeleine, please contact <a href="mailto:sydneyoboereeds@gmail.com">
              {" "}
              <u>sydneyoboereeds@gmail.com</u>
            </a> for online or face-to-face lessons in Queenscliff, NSW.</p>
        </div>
      </section>
    );
  }
}

export default About;
