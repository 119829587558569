const allProducts = {
  price_1OUiBCDBTPoEHOlT6jv4Lyq5: {
    name: "Beginner reed*",
    price: "24.99",
    price_id: "price_1OUiBCDBTPoEHOlT6jv4Lyq5",
    quantity: 0,
  },
  price_1OUiDCDBTPoEHOlTxHaZY53i: {
    name: "Standard reed",
    price: "28.99",
    price_id: "price_1OUiDCDBTPoEHOlTxHaZY53i",
    quantity: 0,
  },
  price_1OUi9xDBTPoEHOlTn4M0ON8L: {
    name: "Professional reed",
    price: "49.99",
    price_id: "price_1OUi9xDBTPoEHOlTn4M0ON8L",
    quantity: 0,
  },
};

export { allProducts };
